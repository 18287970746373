import { useEffect, useState } from "react";

function Captcha({ onCaptchaChange, resetCaptcha, setResetCaptcha }) {
  const [captchaToken, setCaptchaToken] = useState('');
  const [isCaptchaSuccess, setCaptchaSuccess] = useState(false);
  const [isCaptchaLoading, setIsCaptchaLoading] = useState(true); // Индикатор загрузки капчи
  const [isCaptchaRendered, setIsCaptchaRendered] = useState(false); // Состояние рендеринга капчи
  const [error, setError] = useState('');

  useEffect(() => {
    // Добавляем скрипт reCAPTCHA в <head>, если его нет
    if (!document.getElementById('recaptcha-script')) {
      const script = document.createElement('script');
      script.id = 'recaptcha-script';
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }

    // Ожидаем, пока reCAPTCHA будет готова
    const waitForReCaptcha = () => {
      if (window.grecaptcha && window.grecaptcha.ready) {
        window.grecaptcha.ready(() => {
          renderReCaptcha(); // Рендерим капчу после того, как grecaptcha готова
          setIsCaptchaLoading(false); // Скрываем индикатор загрузки
        });
      } else {
        setTimeout(waitForReCaptcha, 100); // Пробуем снова через 100 мс, если grecaptcha не готова
      }
    };

    waitForReCaptcha();
  }, []);

  useEffect(() => {
    // Сбрасываем капчу при изменении resetCaptcha
    if (resetCaptcha && window.grecaptcha) {
      window.grecaptcha.ready(() => {
        // Проверяем, была ли капча отрендерена перед сбросом
        if (isCaptchaRendered && window.grecaptcha.reset) {
          window.grecaptcha.reset(); // Сброс капчи

          setCaptchaToken('');
          setCaptchaSuccess(false);
          setError('');

          renderReCaptcha(); // Рендерим заново после сброса
        }
      });

      // Сбрасываем флаг resetCaptcha после рендеринга новой капчи
      setTimeout(() => setResetCaptcha(false), 500);
    }
  }, [resetCaptcha]);

  useEffect(() => {
    // Сообщаем родителю о текущем состоянии капчи
    if (onCaptchaChange) {
      onCaptchaChange({ captchaToken, isCaptchaSuccess });
    }
  }, [captchaToken, isCaptchaSuccess, onCaptchaChange]);

  const renderReCaptcha = () => {
    const recaptchaContainer = document.getElementById('recaptcha-container');
    if (!recaptchaContainer) return;

    // Убедимся, что капча не была отрендерена ранее
    if (window.grecaptcha && !isCaptchaRendered) {
      window.grecaptcha.render(recaptchaContainer, {
        sitekey: '6LdD1UsqAAAAAJRPPOD9hUTE5I7z9VEsnJt-LTmK',
        callback: (token) => {
          setCaptchaToken(token);
          setCaptchaSuccess(true);
          setError('');
        },
        'expired-callback': () => {
          setCaptchaSuccess(false);
          setError('Ошибка проверки капчи. Попробуйте еще раз');
        },
      });

      setIsCaptchaRendered(true); // Отмечаем, что капча отрендерена
    }
  };

  return (
    <>
      <div id="recaptcha-container" className="modal__captcha"></div>
      {isCaptchaLoading && <span>Пожалуйста, подождите, идёт загрузка капчи...</span>}
      {error && <span className="form__info" style={{ textAlign: "center" }}>{error}</span>}
    </>
  );
}

export default Captcha;
