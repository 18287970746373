import React, { useState, useEffect  } from 'react';
import './Checkmark.css';

const Checkmark = ({animate, type}) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (animate) {
      setIsAnimating(true);
    } else {
      setIsAnimating(false);
    }
  }, [animate]);

  const SuccessIcon = () => (
    <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 25L27.3077 44L58.5 7"
        stroke="white"
        strokeWidth="13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const ErrorIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 96.574 96.574" xmlSpace="preserve">
      <g fill="#ffffff">
        <path d="M60.922,0.605C60.545,0.218,60.028,0,59.488,0H37.086c-0.54,0-1.057,0.219-1.434,0.605c-0.378,0.386-0.58,0.91-0.565,1.45 l1.68,60.798c0.029,1.082,0.916,1.943,1.999,1.943h19.046c1.083,0,1.971-0.861,1.999-1.945l1.677-60.796 C61.502,1.516,61.299,0.992,60.922,0.605z"></path>
        <path d="M57.771,75.034H38.804c-1.104,0-2,0.896-2,2v17.54c0,1.104,0.896,2,2,2h18.969c1.104,0,2-0.896,2-2v-17.54 C59.771,75.93,58.877,75.034,57.771,75.034z"></path>
      </g>
      <rect width="96.574" height="96.574" fill="none"></rect>
    </svg>
  );

  const icon = type === 'success' ? <SuccessIcon /> : <ErrorIcon />;
  return (
    <div className={`check-container ${isAnimating ? 'animate' : ''}`}>
      <div className="check-background">{icon}</div>
      <div className="check-shadow"></div>
    </div>
  );
};

export default Checkmark;
