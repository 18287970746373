import { forwardRef } from "react";
import Section from "../Section/Section";
import ContactsForm from "../ContactsForm/ContactsForm";
import ContactsInfo from "../ContactsInfo/ContactsInfo";
import './Contacts.css';

const Contacts = forwardRef(({ onFormSubmit }, ref) => (
  <Section
    ref={ref}
    title="Наши контакты"
    subTitle="Оставьте заявку и наш менеджер свяжется с вами в ближайшее время"
    className="contacts"
    id="contacts"
  >
    <div className="contacts__content">
      <ContactsForm
        onFormSubmit={onFormSubmit}  // Передаём функцию отправки данных
      />
      <ContactsInfo />
    </div>
  </Section>
));

Contacts.displayName = 'Contacts';

export default Contacts;