import Modal from "../Modal";

import Checkmark from "../../Checkmark/Checkmark";

import './RequestModal.css'

function RequestModal({ isOpen, onClose, props }) {
  let {message, type} = props;

  if (!type) type = 'loading';

  let title = 'Внимание';
  let subtitle = 'Возможно произошла ошибка';

  if ( type === 'success' ) {
    title = 'Заявка отправлена';
    subtitle = 'Рассмотрим в ближайшее время';
  }
  else if ( type === 'loading' ) {
    message = 'Отправляем..'
    subtitle = 'Это может занять некоторое время';
  }
  else if ( (type === 'error' || type === 'captcha') && !message ) {
    message = 'К сожалению, ваша заявка не была отправлена, форма в данный момент недоступна'
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="modal__form">
        <span className="form__tag"></span>
        <div className="form__icon">
          <Checkmark animate={isOpen} type={type} />
        </div>
        <div className="form__header" style={{ textAlign: "center", border: 'none' }}>
          <h2 className='form__title'>{title}</h2>
          <span className="form__subtitle">{subtitle}</span>
        </div>
        {message && <span className="form__info" style={{ textAlign: "center" }}>{message}</span>}
        <div className="form__footer" style={{ marginTop: '1em', justifyContent: "center" }}>
          <button className="form__submit form__submit_wide" onClick={onClose}>Хорошо</button>
        </div>
      </div>
    </Modal>
  );
}

export default RequestModal;