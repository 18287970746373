import { useRef, useState } from "react";
import Header from "../../Header/Header";
import Menu from "../../Menu/Menu";
import Main from "../../Main/Main";
import Spinner from "../../Spinner/Spinner";
import Categories from "../../Categories/Categories";
import Contacts from "../../Contacts/Contacts";
import Map from "../../Map/Map";
import Footer from "../../Footer/Footer";
import Modal from "../../Modal/Modal";
import CategoryModal from "../../Modal/CategoryModal/CategoryModal";
import RequestModal from "../../Modal/RequestModal/RequestModal";
import MenuItems from "./MenuItems/MenuItems";
import Captcha from "../../Captcha/Captcha";
import api from "../../../utils/api";

function MainPage(props) {
  const { isBurgerOpen, setBurgerOpen, isAccessibilityModalOpen, setAccessibilityModalOpen, darkMode } = props;

  const [optionsCategoryModal, setOptionsCategoryModal] = useState({open : false, data: { title: null, subTitle: null, price: null, info: null }});
  const [optionsRequestModal, setOptionsRequestModal] = useState({open : false, type: null, message: null});

  const [isFormSubmitting, setFormSubmitting] = useState(false);
  const [storedFormData, setStoredFormData] = useState(null);
  const [captchaToken, setCaptchaToken] = useState('');
  const [isCaptchaSuccess, setCaptchaSuccess] = useState(false);
  const [resetCaptcha, setResetCaptcha] = useState(false); // Управляем сбросом капчи

  const categoriesRef = useRef();
  const contactsRef = useRef();

  const scrollToCategories = () => {
    categoriesRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const scrollToContacts = () => {
    contactsRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const handleCategoryModalClick = (data) => {
    setOptionsCategoryModal({ open: true, data });
  }

  const handleSubmitCategoryModal = () => {
    closeModals();
    scrollToContacts();
  }

  // Этап 1: Прохождение капчи
  const handleFormSubmit = (formData) => {
    // Если капча не пройдена, сохраняем данные формы и показываем капчу
    if (!isCaptchaSuccess || !captchaToken) {
      setStoredFormData(formData);  // Сохраняем данные формы

      // Показываем модальное окно с капчей
      setOptionsRequestModal({
        open: true,
        type: 'captcha',
        message: null,
      });

      return;
    }

    // Если капча уже пройдена и форма еще не отправляется
    if (!isFormSubmitting) {
      sendFormData(formData); // Переходим к отправке данных
    }
  };

// Эта функция будет вызываться при изменении состояния капчи (после её прохождения)
  const handleCaptchaChange = ({ captchaToken, isCaptchaSuccess }) => {
    setCaptchaToken(captchaToken); // Обновляем токен капчи
    setCaptchaSuccess(isCaptchaSuccess); // Обновляем состояние капчи

    // Если капча пройдена и форма ожидает отправки
    if (isCaptchaSuccess && storedFormData && !isFormSubmitting) {
      sendFormData(storedFormData); // Отправляем сохранённые данные формы
    }
  };

// Этап 2: Отправка данных формы
  const sendFormData = (formData) => {
    setFormSubmitting(true); // Устанавливаем флаг, что отправка началась

    // Изменяем текст в модальном окне на "Отправляем..."
    setOptionsRequestModal({
      open: true,
      type: 'loading'
    });

    // Таймер на случай отсутствия ответа от сервера
    const timeoutId = setTimeout(() => {
      // Этап 3: Обработка ошибки (нет ответа от сервера)
      handleRequestError('Превышено время ожидания ответа от сервера');
    }, 5000);

    // Отправка данных на сервер
    api.addNewRequest(formData, '/request')
      .then((res) => {
        clearTimeout(timeoutId); // Очищаем таймер при успешном ответе

        // Этап 3: Обработка результата запроса
        if (res?.error) {
          handleRequestError(res.error);
        } else {
          handleRequestSuccess();
        }
      })
      .catch((err) => {
        clearTimeout(timeoutId); // Очищаем таймер при ошибке

        let textError = err.message || 'Произошла ошибка'

        if ( err?.message === 'Failed to fetch') {
          textError = 'Ошибка при отправке данных'
        } else if ( err?.message === 'Validation failed') {
          textError = `Одно из полей было заполнено с ошибкой.
            Обратите внимание, что имя является обязательным и должно состоять из более 2 символов.
            А номер телефона соответствовать маске +7(9__)___-__-__.
            Комментарий не является обязательным`
        }

        handleRequestError(textError)
      })
      .finally(() => {
        setFormSubmitting(false); // Сбрасываем флаг отправки формы
        setStoredFormData(null);  // Очищаем сохранённые данные формы
      });
  };

// Этап 3: Успешная отправка данных
  const handleRequestSuccess = () => {
    setOptionsRequestModal({
      open: true,
      type: 'success',
      message: null, // Нет сообщения об ошибке, успешная отправка
    });
  };

// Этап 3: Обработка ошибки при отправке данных
  const handleRequestError = (message = null) => {
    setOptionsRequestModal({
      open: true,
      type: 'error',
      message: message, // Отображаем сообщение об ошибке
    });
  }

  function closeModals() {
    setOptionsCategoryModal({
      open : false,
      data: {
        title: null,
        subTitle: null,
        price: null,
        info: null
      }
    });

    setOptionsRequestModal({
      open: false,
      type: null,
      message: null
    });

    setTimeout( () => setResetCaptcha(true), 500);
  }

  return (
    <>
      <Header darkMode={darkMode} />
      <Menu
        element={
          <MenuItems
            isBurgerOpen={isBurgerOpen}
            toggleBurger={() => setBurgerOpen(!isBurgerOpen)}
            scrollToCategories={scrollToCategories}
            scrollToContacts={scrollToContacts}
          />
        }
        isBurgerOpen={isBurgerOpen}
        toggleBurger={() => setBurgerOpen(!isBurgerOpen)}
        toggleAccessibilityModal={() => setAccessibilityModalOpen(!isAccessibilityModalOpen)}
      />

      <Main className="main">
        <Spinner scrollToContacts={scrollToContacts} />
        <Categories ref={categoriesRef} onCategoryModal={handleCategoryModalClick} />
        <Contacts
          ref={contactsRef}
          onFormSubmit={handleFormSubmit}  // Передаем функцию отправки данных
        />
        <Map />
      </Main>

      <Footer />

      <CategoryModal
        props={optionsCategoryModal.data}
        isOpen={optionsCategoryModal.open}
        onSubmit={handleSubmitCategoryModal}
        onClose={closeModals}
      />

      <Modal isOpen={optionsRequestModal.open && optionsRequestModal.type === 'captcha' && !isCaptchaSuccess} onClose={closeModals}>
        <div className="modal__form">
          <div className="form__header" style={{ textAlign: "center", border: 'none' }}>
            <h2 className="form__title">Последний шаг</h2>
            <span className="form__subtitle">Нам нужно убедиться, что вы не робот</span>
          </div>

          <Captcha onCaptchaChange={handleCaptchaChange} resetCaptcha={resetCaptcha} setResetCaptcha={setResetCaptcha} />

          {optionsRequestModal.message && <span className="form__info" style={{ textAlign: "center" }}>{optionsRequestModal.message}</span>}
        </div>
      </Modal>

      <RequestModal
        props={optionsRequestModal}
        isOpen={optionsRequestModal.open && isCaptchaSuccess}
        onClose={closeModals}
      />
    </>
  );
}

export default MainPage;
